/* You can add global styles to this file, and also import other style files */
@import "~@aws-amplify/ui-angular/theme.css";

@font-face {
  font-family: "Quicksand";
  src: url("assets/fonts/Quicksand-VariableFont_wght.ttf");
}

body {
  font-family: "Quicksand", "Arial", sans-serif;
  font-size: smaller;
}

caption {
  color: white;
  font-size: smaller;
}

/*------------------------------------
- COLOR primary
------------------------------------*/
.text-bg-primary {
  background-color: #0b4d80 !important;
}

.alert-primary {
  color: #01090f;
  background-color: #65b4f1;
  border-color: #52abf0;
}

.alert-primary hr {
  border-top-color: #3aa0ee;
}

.alert-primary .alert-link {
  color: #000000;
}

.badge-primary {
  color: #fff;
  background-color: #0b4d80;
}

.badge-primary[href]:hover,
.badge-primary[href]:focus {
  color: #fff;
  background-color: #063051;
}

.bg-primary {
  background-color: #0b4d80 !important;
}

a {
  cursor: pointer;
}
a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #063051 !important;
}

.border-primary {
  border-color: #0b4d80 !important;
}

.btn-primary {
  color: #fff;
  background-color: #0b4d80;
  border-color: #0b4d80;
}

.btn-primary:hover {
  color: #fff;
  background-color: #08395f;
  border-color: #063051;
}

.btn-primary:focus,
.btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(11, 77, 128, 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #0b4d80;
  border-color: #0b4d80;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #063051;
  border-color: #052842;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(11, 77, 128, 0.5);
}

.btn-outline-primary {
  color: #0b4d80;
  background-color: transparent;
  border-color: #0b4d80;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #0b4d80;
  border-color: #0b4d80;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(11, 77, 128, 0.5);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #0b4d80;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #0b4d80;
  border-color: #0b4d80;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(11, 77, 128, 0.5);
}

.list-group-item-primary {
  color: #01090f;
  background-color: #52abf0;
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: #01090f;
  background-color: #3aa0ee;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #01090f;
  border-color: #01090f;
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #52abf0;
}

.table-hover .table-primary:hover {
  background-color: #3aa0ee;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #3aa0ee;
}

.text-primary {
  color: #0b4d80 !important;
}

a.text-primary:hover,
a.text-primary:focus {
  color: #063051 !important;
}

/*------------------------------------
- COLOR secondary
------------------------------------*/
.text-bg-secondary {
  background-color: #a6a6a6 !important;
}

.alert-secondary {
  color: #686868;
  background-color: #10c10c10;
  border-color: #10110110;
}

.alert-secondary hr {
  border-top-color: #f5f5f5;
}

.alert-secondary .alert-link {
  color: #4f4f4f;
}

.badge-secondary {
  color: #212529;
  background-color: #a6a6a6;
}

.badge-secondary[href]:hover,
.badge-secondary[href]:focus {
  color: #212529;
  background-color: #8c8c8c;
}

.bg-secondary {
  background-color: #a6a6a6 !important;
}

a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #8c8c8c !important;
}

.border-secondary {
  border-color: #a6a6a6 !important;
}

.btn-secondary {
  color: #212529;
  background-color: #a6a6a6;
  border-color: #a6a6a6;
}

.btn-secondary:hover {
  color: #212529;
  background-color: #949494;
  border-color: #8c8c8c;
}

.btn-secondary:focus,
.btn-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(166, 166, 166, 0.5);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #212529;
  background-color: #a6a6a6;
  border-color: #a6a6a6;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #212529;
  background-color: #8c8c8c;
  border-color: #848484;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(166, 166, 166, 0.5);
}

.btn-outline-secondary {
  color: #a6a6a6;
  background-color: transparent;
  border-color: #a6a6a6;
}

.btn-outline-secondary:hover {
  color: #212529;
  background-color: #a6a6a6;
  border-color: #a6a6a6;
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(166, 166, 166, 0.5);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #a6a6a6;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #212529;
  background-color: #a6a6a6;
  border-color: #a6a6a6;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(166, 166, 166, 0.5);
}

.list-group-item-secondary {
  color: #686868;
  background-color: #10110110;
}

.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
  color: #686868;
  background-color: #f5f5f5;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #212529;
  background-color: #686868;
  border-color: #686868;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #10110110;
}

.table-hover .table-secondary:hover {
  background-color: #f5f5f5;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #f5f5f5;
}

th {
  color: rgb(51, 97, 132);
}

.text-secondary {
  color: #a6a6a6 !important;
}

a.text-secondary:hover,
a.text-secondary:focus {
  color: #8c8c8c !important;
}

/*------------------------------------
- COLOR danger
------------------------------------*/
.text-bg-danger {
  background-color: #da1e26 !important;
}

.alert-danger {
  color: #6e0f13;
  background-color: #f7cccd;
  border-color: #f5babc;
}

.alert-danger hr {
  border-top-color: #f2a3a7;
}

.alert-danger .alert-link {
  color: #41090b;
}

.badge-danger {
  color: #fff;
  background-color: #da1e26;
}

.badge-danger[href]:hover,
.badge-danger[href]:focus {
  color: #fff;
  background-color: #ad171e;
}

.bg-danger {
  background-color: #da1e26 !important;
}

a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #ad171e !important;
}

.border-danger {
  border-color: #da1e26 !important;
}

.btn-danger {
  color: #fff;
  background-color: #da1e26;
  border-color: #da1e26;
}

.btn-danger:hover {
  color: #fff;
  background-color: #ba1920;
  border-color: #ad171e;
}

.btn-danger:focus,
.btn-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(218, 30, 38, 0.5);
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #da1e26;
  border-color: #da1e26;
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #ad171e;
  border-color: #9f151b;
}

.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(218, 30, 38, 0.5);
}

.btn-outline-danger {
  color: #da1e26;
  background-color: transparent;
  border-color: #da1e26;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #da1e26;
  border-color: #da1e26;
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(218, 30, 38, 0.5);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #da1e26;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #da1e26;
  border-color: #da1e26;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(218, 30, 38, 0.5);
}

.list-group-item-danger {
  color: #6e0f13;
  background-color: #f5babc;
}

.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
  color: #6e0f13;
  background-color: #f2a3a7;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #6e0f13;
  border-color: #6e0f13;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5babc;
}

.table-hover .table-danger:hover {
  background-color: #f2a3a7;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f2a3a7;
}

.text-danger {
  color: #da1e26 !important;
}

a.text-danger:hover,
a.text-danger:focus {
  color: #ad171e !important;
}

/*------------------------------------
- COLOR success
------------------------------------*/
.text-bg-success {
  background-color: #07d188 !important;
}

.alert-success {
  color: #035a3a;
  background-color: #a7fcdd;
  border-color: #94fbd6;
}

.alert-success hr {
  border-top-color: #7bfacc;
}

.alert-success .alert-link {
  color: #01291a;
}

.badge-success {
  color: #212529;
  background-color: #07d188;
}

.badge-success[href]:hover,
.badge-success[href]:focus {
  color: #212529;
  background-color: #059f67;
}

.bg-success {
  background-color: #07d188 !important;
}

a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #059f67 !important;
}

.border-success {
  border-color: #07d188 !important;
}

.btn-success {
  color: #212529;
  background-color: #07d188;
  border-color: #07d188;
}

.btn-success:hover {
  color: #212529;
  background-color: #05ae71;
  border-color: #059f67;
}

.btn-success:focus,
.btn-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(7, 209, 136, 0.5);
}

.btn-success.disabled,
.btn-success:disabled {
  color: #212529;
  background-color: #07d188;
  border-color: #07d188;
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #212529;
  background-color: #059f67;
  border-color: #04905e;
}

.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(7, 209, 136, 0.5);
}

.btn-outline-success {
  color: #07d188;
  background-color: transparent;
  border-color: #07d188;
}

.btn-outline-success:hover {
  color: #212529;
  background-color: #07d188;
  border-color: #07d188;
}

.btn-outline-success:focus,
.btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(7, 209, 136, 0.5);
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #07d188;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #212529;
  background-color: #07d188;
  border-color: #07d188;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(7, 209, 136, 0.5);
}

.list-group-item-success {
  color: #035a3a;
  background-color: #94fbd6;
}

.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
  color: #035a3a;
  background-color: #7bfacc;
}

.list-group-item-success.list-group-item-action.active {
  color: #212529;
  background-color: #035a3a;
  border-color: #035a3a;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #94fbd6;
}

.table-hover .table-success:hover {
  background-color: #7bfacc;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #7bfacc;
}

.text-success {
  color: #07d188 !important;
}

a.text-success:hover,
a.text-success:focus {
  color: #059f67 !important;
}

.btn-delete-image {
  background-image: url(assets/images/icons/rejected.svg);
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 20px 20px;
  background-position-x: 8px;
  padding-left: 25px;
}

.btn-blocked-image {
  background-image: url(assets/images/icons/blocked.svg);
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 20px 20px;
  background-position-x: 8px;
  padding-left: 25px;
}

.btn-active-image {
  background-image: url(assets/images/icons/available.svg);
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 20px 20px;
  background-position-x: 8px;
  padding-left: 25px;
}

.btn-edit-image {
  background-image: url(assets/images/icons/configuracion.svg);
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 20px 20px;
  background-position-x: 8px;
  padding-left: 25px;
}

.btnDelete:hover {
  background-color: red;
  color: white;
}

.box-details-cab {
  border: 2px solid #95c5fc;
  border-radius: 10px;
  padding-top: 10px;
  height: 100%;
}

.table-container {
  max-height: 600px;
  overflow-y: auto;
  font-size: 12.5px;
}

/* Para ocultar búsqueda que viene por defecto del datatable js */
.dataTables_filter {
  display: none;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  padding: 0 !important;
  border: 0 !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  background: white !important;
  border: 0 !important;
}

.pagination .page-item.previous .page-link,
.pagination .page-item.next .page-link {
  font-weight: bold;
  color: rgb(51, 97, 132);
  border-radius: 30px;
}

.pagination .page-item:not(.previous):not(.next) .page-link {
  background-color: white;
  color: rgb(51, 97, 132);
  border-radius: 0;
  border: 0;
}

.active > .page-link,
.page-link.active {
  text-decoration: underline;
  font-weight: bold;
  border-color: white;
}

div.dataTables_wrapper div.dataTables_paginate {
  display: flex;
  align-items: start;
  padding-right: 85%;
}

.input-search-datatable {
  height: 35px;
  /* width: 110%; */
  border-radius: 35px;
  border: 2px solid #b3c6ea;
  /* Evita que aparezca un contorno al enfocar */
  outline: none;
  text-align: center;
  margin-right: 10px;
}

.input-search-datatable:focus {
  border-color: #95c5fc;
}

.input-search-datatable-image {
  background-image: url(assets/images/icons/buscarvehiculos.svg);
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 30px 30px;
}

.input-search-datatable-image-drivers {
  background-image: url(assets/images/icons/buscarconductor.svg);
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 23px 30px;
  background-position-x: 6px;
}

.input-search-datatable-image-envasados {
  background-image: url(assets/images/icons/buscarvehiculo1.svg);
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 35px 35px;
  background-position-x: -5px;
}

.input-search-datatable-image-tractos {
  background-image: url(assets/images/icons/buscarvehiculo.svg);
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 35px 30px;
  background-position-x: -6px;
}

.input-search-datatable-image-users {
  background-image: url(assets/images/icons/buscarusuario.svg);
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 23px 24px;
  background-position-x: 6px;
}

.btn-btn-create {
  background-color: white;
  color: #336184;
  height: 35px;
  /* width: 150px; */
  border-radius: 30px;
  border: 2px solid #b3c6ea;
  /* margin-right: 15px;
  margin-left: 15px; */
}

.btn-btn-create:hover {
  background-color: rgb(76, 209, 135);
  color: white;
}

.btn-refresh {
  height: 35px;
  width: 50px;
}

.btn-refresh-image {
  background-image: url(assets/images/icons/actualizar.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 25px 25px;
}

.btn-create-image-users {
  background-image: url(assets/images/icons/crear_usuario.svg);
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 30px 30px;
  background-position-x: 10px;
  padding-left: 30px;
}

.btn-create-image-drivers {
  background-image: url(assets/images/icons/crear_conductor.svg);
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 30px 30px;
  background-position-x: 10px;
  padding-left: 30px;
}

.btn-create-image-vehicles {
  background-image: url(assets/images/icons/crear_vehiculo.svg);
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 30px 30px;
  background-position-x: 10px;
  padding-left: 30px;
}

.btn-create-image-envasado {
  background-image: url(assets/images/icons/envasado.svg);
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 25px 25px;
  background-position-x: 10px;
  padding-left: 30px;
}

.btn-create-image-cisterna {
  background-image: url(assets/images/icons/cisternas.svg);
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 25px 25px;
  background-position-x: 10px;
  padding-left: 30px;
}

.btn-create-image-tractos {
  background-image: url(assets/images/icons/tractos.svg);
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 25px 25px;
  background-position-x: 10px;
  padding-left: 10px;
}

.btn-create-image-tanque {
  background-image: url(assets/images/icons/tanques.svg);
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 25px 25px;
  background-position-x: 10px;
  padding-left: 30px;
}

.btn-create-image-semirremolque {
  background-image: url(assets/images/icons/semirremolques.svg);
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 25px 25px;
  background-position-x: 10px;
  padding-left: 30px;
}

.div-box-sap-id {
  display: flex;
  justify-content: center;
  padding: 20px 0 20px 0;
}

.divCenter {
  display: flex;
  justify-content: center;
}

.detail-punctuation {
  border: 2px solid #2d9bf0;
  border-radius: 20px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.btn-save-punctuation {
  background-color: white;
  border: 1px solid #2d9bf0;
  color: #2d9bf0;
  width: 90px;
  height: 40px;
}

.btn-save-punctuation:hover {
  background-color: white;
  border: 1px solid #2d9bf0;
  color: #2d9bf0;
  width: 90px;
  height: 40px;
}

.btn-submit-punctuation {
  border: 2px solid #2d9bf0;
  background-color: #2d9bf0;
  color: white;
  width: 90px;
  height: 40px;
}

.btn-submit-punctuation:hover {
  border: 2px solid #2d9bf0;
  background-color: #2d9bf0;
  color: white;
  width: 90px;
  height: 40px;
}

.btn-submit-punctuation:disabled {
  border: 2px solid #2d9bf0;
  background-color: #2d9bf0;
  color: white;
  width: 90px;
  height: 40px;
}

.btn-load {
  border: 2px solid #2d9bf0;
  background-color: #2d9bf0;
  color: white;
  width: 90px;
  height: 37px;
}

.custom-file-upload {
  display: inline-block;
  position: relative;
  font-family: Arial, sans-serif;
}

.custom-file-upload input[type="file"] {
  display: none;
}

.custom-file-upload i {
  font-size: 18px;
  margin-right: 5px;
}

.comment {
  max-width: 100%;
  max-width: -webkit-fill-available;
}
.btn-rectangle {
  border-radius: 5px;
}
